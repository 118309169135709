import React, { useState, useEffect } from 'react';
import { useUpdateDocument } from '../../../hooks/useUpdateDocument';
import { useFetchDocument } from '../../../hooks/useFetchDocument';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Lottie from 'react-lottie';
import animationData from '../../../lotties/loading';


const TermsUpdate = () => {


    const { id } = useParams();
    const { document: term } = useFetchDocument("terms", id);
    const [title, setTitle] = useState("");
    const [page1, setPage1] = useState("");
    const [page2, setPage2] = useState("");
    const [page3, setPage3] = useState("");
    const [formError, setFormError] = useState("");

    // fill form data
    useEffect(() => {
        if (term) {
            setTitle(term.title);
            setPage1(term.page1);
            setPage2(term.page2);
            setPage3(term.page3);
        }
    }, [term]);



    const navigate = useNavigate();

    const { updateDocument, response } = useUpdateDocument("terms");

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError("");

        // check values
        if (!title || !page1 || !page2 || !page3) {
            setFormError("Por favor, preencha todos os campos!");
        }

        if (formError) return

        const data = {
            title,
            page1,
            page2,
            page3,
        };

        updateDocument(id, data);
    };

    const handleChangePage1 = html => {
        setPage1(html);
    };

    const handleChangePage2 = html => {
        setPage2(html);
    };

    const handleChangePage3 = html => {
        setPage3(html);
    };



    const modules = {
        toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                {
                    color: [],
                },
            ],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["link", "image", "video"],
            ["clean"],
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div className='mt-3 mb-5'>
            <div className='container bg-light py-5'>
                <div className='row'>
                    <div className='mx-auto col-md-4 text-center'>
                        <h2>Editando Terms: {title} </h2>
                        <p>Altere os dados do Terms como desejar</p>
                        {(response.success === true) && (
                            <div className="alert alert-success" role="alert">
                                Atualizado com sucesso
                            </div>
                        )

                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6 mx-auto card p-5'>
                        <form onSubmit={handleSubmit}>

                            <div className='mb-3'>
                                <label htmlFor="title" className="form-label">Title</label>
                                <input

                                    className='form-control'
                                    type="text"
                                    name="text"
                                    required
                                    placeholder="Title"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor="page1" className="form-label">Page 1</label>
                                <ReactQuill
                                    modules={modules}
                                    theme="snow"
                                    value={page1}
                                    onChange={handleChangePage1}
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor="page2" className="form-label">Page 2</label>
                                <ReactQuill
                                    modules={modules}
                                    theme="snow"
                                    value={page2}
                                    onChange={handleChangePage2}
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor="page3" className="form-label">Page 3</label>
                                <ReactQuill
                                    modules={modules}
                                    theme="snow"
                                    value={page3}
                                    onChange={handleChangePage3}
                                />
                            </div>

                            {!response.loading && <button className="btn btn-md btn-success">Atualizar Terms!</button>}
                            {response.loading && (
                                <button className="btn" disabled>
                                    <Lottie
                                        options={defaultOptions}
                                        height={400}
                                        width={400}
                                    />
                                </button>
                            )}
                            {(response.error || formError) && (
                                <p className="error">{response.error || formError}</p>
                            )}
                        </form>
                    </div>
                </div>

            </div>
            {
                (response.success === true) && (
                    setTimeout(() => {
                        navigate("/admin/terms")
                    }, 2000)
                )
            }
        </div>
    );
};



export default TermsUpdate;