import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-autofill-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css';
import 'datatables.net-buttons/js/buttons.colVis.mjs';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-buttons/js/buttons.print.mjs';
import 'datatables.net-colreorder-bs5';
import 'datatables.net-fixedcolumns-bs5';
import 'datatables.net-fixedheader-bs5';
import 'datatables.net-keytable-bs5';
import 'datatables.net-responsive-bs5';
import 'datatables.net-rowgroup-bs5';
import 'datatables.net-rowreorder-bs5';
import 'datatables.net-scroller-bs5';
import 'datatables.net-searchbuilder-bs5';
import 'datatables.net-searchpanes-bs5';
import 'datatables.net-select-bs5';
import 'datatables.net-staterestore-bs5';


import { useFetchDocuments } from "../../../hooks/useFetchDocuments";


const CallHistory = () => {

    const DateFormatter = ({ isoString }) => {
        // Cria uma instância de Date a partir da string UTC
        const utcDate = new Date(isoString);

        // Extrai as partes da data
        const day = String(utcDate.getUTCDate()).padStart(2, '0'); // Dia com dois dígitos
        const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0'); // Mês com dois dígitos (UTCMonth é zero-based)
        const year = utcDate.getUTCFullYear();
        const hours = String(utcDate.getUTCHours()).padStart(2, '0'); // Horas com dois dígitos
        const minutes = String(utcDate.getUTCMinutes()).padStart(2, '0'); // Minutos com dois dígitos

        // Formata a data no formato dia/mes/ano hora:minutos
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;


        return (
            <div>
                <p>{formattedDate}</p>
            </div>
        );
    };


    const { documents: callHistory } = useFetchDocuments("call-history", null, null);
    const tableRef = useRef(null);
    useEffect(() => {
        if (callHistory) {
            $(tableRef.current).DataTable({
                retrieve: true,
                dom: 'BCRSZlfrtip',
                buttons: [
                    'excelHtml5',
                    'autoWidth',
                    'pdf',
                    'csv'
                ]
            });
        }


    }, [callHistory]);

    return (
        <div className='mt-3 mb-5'>
            <div className='container'>
                <div className='row'>
                    <div className='mx-auto col-md-4 text-center'>
                        <h2 >Call History</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 mx-auto'>
                        <table ref={tableRef} className="display stripe w-100" data-page-length="10" >
                            <thead>
                                <tr>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>first User Email</th>
                                    <th>second User Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {callHistory &&
                                    callHistory.map((value) => (
                                        <tr key={value.uid}>

                                            <td >
                                                <p>{value.startDate ? <DateFormatter isoString={value.startDate} /> : ""}</p>
                                            </td>
                                            <td >
                                                <p>{value.endDate ? <DateFormatter isoString={value.endDate} /> : ""} </p>
                                            </td>
                                            <td >
                                                <p>{value.firstUserEmail}</p>
                                            </td>
                                            <td >
                                                <p>{value.secondUserEmail}</p>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );


};



export default CallHistory;