import React, { useEffect } from 'react';
import Moment from 'moment';
import useFetchCallGroup from "../../../hooks/useFetchCallGroup";

const Dashboard = () => {
    const { data, loading, error, refetch } = useFetchCallGroup();

    useEffect(() => {
        const interval = setTimeout(() => {
            refetch(); // Dispara a chamada da API novamente a cada 2 segundos
        }, 10000);

        return () => clearTimeout(interval); // Limpa o timeout ao desmontar o componente
    }, [refetch]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    const callGroup = data?.callGroup || [];

    return (
        <div className='mt-3 mb-5'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h3 className='mb-3'>
                            Lista de usuários waiting room
                        </h3>
                        <div className='row border'>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-2 text-center'>
                                        <h5>Nome</h5>
                                    </div>
                                    <div className='col-md-3 text-center'>
                                        <h5>E-mail</h5>
                                    </div>
                                    <div className='col-md-3 text-center'>
                                        <h5>Data Entrada</h5>
                                    </div>
                                    <div className='col-md-1 text-center'>
                                        <h5>Resultado Teste</h5>
                                    </div>
                                    <div className='col-md-3 text-center'>
                                        <h5>Localização</h5>
                                    </div>
                                </div>
                                <div className='w-100'></div>
                                {callGroup.length > 0 ? (
                                    callGroup.map((user, index) => (
                                        <div className='row' key={index}>
                                            <div className='col-md-2 text-center'>
                                                <p>{user.name}</p>
                                            </div>
                                            <div className='col-md-3 text-center'>
                                                <p>{user.email}</p>
                                            </div>
                                            <div className='col-md-3 text-center'>
                                                <p>{Moment(user.date).format('DD/MM/YYYY HH:mm:ss')}</p>
                                            </div>
                                            <div className='col-md-1 text-center'>
                                                <p>{user.result}</p>
                                            </div>
                                            <div className='col-md-3 text-center'>
                                                <p>{user.location}</p>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className='text-center'>Nenhum usuário encontrado.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
