const LayoutLogin = ({ children }) => {
    return (
        <div>

            <div style={{ minHeight: "300px" }}>
                {children}
            </div>
            <div style={{ position: "absolute", bottom: "0", textAlign: "center", width: "100%" }}>
                <footer className="bg-light text-center text-lg-start fixed-bottom" >
                    <div className="text-center p-3" >
                        © 2024 Copyright:
                        <a className="text-dark" href="/">Voxer Global</a>
                    </div>
                </footer>
            </div>

        </div>
    )

}
export default LayoutLogin

