import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-autofill-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css';
import 'datatables.net-buttons/js/buttons.colVis.mjs';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-buttons/js/buttons.print.mjs';
import 'datatables.net-colreorder-bs5';
import 'datatables.net-fixedcolumns-bs5';
import 'datatables.net-fixedheader-bs5';
import 'datatables.net-keytable-bs5';
import 'datatables.net-responsive-bs5';
import 'datatables.net-rowgroup-bs5';
import 'datatables.net-rowreorder-bs5';
import 'datatables.net-scroller-bs5';
import 'datatables.net-searchbuilder-bs5';
import 'datatables.net-searchpanes-bs5';
import 'datatables.net-select-bs5';
import 'datatables.net-staterestore-bs5';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';


import { useFetchDocuments } from "../../../hooks/useFetchDocuments";
import { useDeleteDocument } from "../../../hooks/useDeleteDocument";

import { useState } from 'react';

const ListaInteresse = () => {
    const navigate = useNavigate();
    const { documents: improve } = useFetchDocuments("lista-interesse", null, null);
    const tableRef = useRef(null);
    useEffect(() => {
        if (improve) {
            $(tableRef.current).DataTable({

                retrieve: true,
                dom: 'BCRSZlfrtip',
                buttons: [
                    'excelHtml5',
                    'autoWidth',
                    'pdf',
                    'csv'
                ]
            });
        }


    }, [improve]);

    const { deleteDocument } = useDeleteDocument("lista-interesse");

    function ExcluirItemModal(itemId) {
        setItemToDelete(itemId)
        handleShow()

    };


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [itemToDelete, setItemToDelete] = useState('');

    return (
        <div className='mt-3 mb-5'>
            <div className='container'>
                <div className='row'>
                    <div className='mx-auto col-md-4 text-center'>
                        <h2 >Lista Interesse</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 mx-auto'>
                        <table ref={tableRef} className="display stripe w-100" data-page-length="10" >
                            <thead>
                                <tr>
                                    <th>Created At</th>
                                    <th>E-mail</th>
                                    <th>Message</th>
                                    <th>Name</th>
                                    <th>Device</th>
                                    <th>Phone</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {improve &&
                                    improve.map((value) => (
                                        <tr key={value.uid}>
                                            <td >
                                                <p>{value.createdAt ? new Date(value.createdAt.seconds * 1000).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : ""}</p>
                                            </td>
                                            <td >
                                                <p>{value.email}</p>
                                            </td>
                                            <td >
                                                <p>{value.message}</p>
                                            </td>
                                            <td >
                                                <p>{value.name}</p>
                                            </td>
                                            <td >
                                                <p>{value.option}</p>
                                            </td>
                                            <td >
                                                <p>{value.phone}</p>
                                            </td>
                                            <td>
                                                <button
                                                    onClick={() => ExcluirItemModal(value.id)}

                                                    className="btn btn-outline btn-danger m-1"
                                                >
                                                    Delete
                                                </button>
                                            </td>

                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Danger!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='container'>
                            <p>Do you really want to delete this item?</p>
                            <button
                                onClick={() => {
                                    deleteDocument(itemToDelete)
                                    navigate("/admin/lista-interesse");

                                    handleClose()
                                }}
                                className="btn btn-outline btn-danger m-1"
                            >
                                Yes
                            </button>
                            <button onClick={() => handleClose()}
                                className="btn btn-outline btn-secondary m-1">
                                No
                            </button>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};



export default ListaInteresse;