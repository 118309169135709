import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyD_tfltkHbut7u-vErnMe0HUHYQXJc9ywE",
    authDomain: "voxer-cce7a.firebaseapp.com",
    databaseURL: "https://voxer-cce7a-default-rtdb.firebaseio.com",
    projectId: "voxer-cce7a",
    storageBucket: "voxer-cce7a.appspot.com",
    messagingSenderId: "1034351522239",
    appId: "1:1034351522239:web:33215e9390f3f7e26ab10a",
    measurementId: "G-K8Q8PPP6LJ"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app)

export { db, analytics };