import { useReducer } from "react";
import { db } from "../firebase/config";
import { setDoc, doc } from "firebase/firestore";

const initialState = {
    loading: null,
    error: null,
    success: null
};



let reducerActions = {
    "LOADING": () => {
        return { loading: true, error: null, success: null };
    },
    "INSERTED_DOC": () => {
        return { loading: false, error: null, success: true };
    },
    "ERROR": (action) => {
        return { loading: false, error: action.payload, success: null };
    }
};

function reducer(state, action) {
    let fn = reducerActions[action.type];

    if (fn) {
        return fn(state, action);
    }
    console.log('[WARNING] Action without reducer:', action);
    return state;
}

export const useInsertDocumentByUid = (docCollection) => {
    const [response, dispatch] = useReducer(reducer, initialState);

    const insertDocument = async (document) => {
        dispatch({ type: "LOADING" });


        try {
            const newDocument = { ...document };
            let attr = ''
            if (newDocument.categorie) {
                attr = newDocument.categorie
            } else {
                attr = newDocument.title
            }
            const docRef = doc(db, docCollection, attr);
            const insertedDocument = await setDoc(docRef, newDocument);


            dispatch({
                type: "INSERTED_DOC",
                payload: insertedDocument,
            });
        } catch (error) {
            console.log(error)
            dispatch({ type: "ERROR", payload: error.message });
        }
    };
    return { insertDocument, response };
};