import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-autofill-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css';
import 'datatables.net-buttons/js/buttons.colVis.mjs';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-buttons/js/buttons.print.mjs';
import 'datatables.net-colreorder-bs5';
import 'datatables.net-fixedcolumns-bs5';
import 'datatables.net-fixedheader-bs5';
import 'datatables.net-keytable-bs5';
import 'datatables.net-responsive-bs5';
import 'datatables.net-rowgroup-bs5';
import 'datatables.net-rowreorder-bs5';
import 'datatables.net-scroller-bs5';
import 'datatables.net-searchbuilder-bs5';
import 'datatables.net-searchpanes-bs5';
import 'datatables.net-select-bs5';
import 'datatables.net-staterestore-bs5';


import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import { useState } from 'react';

import { useFetchDocuments } from "../../../hooks/useFetchDocuments";
import { useDeleteDocument } from "../../../hooks/useDeleteDocument";

const Faqs = () => {

    const { documents: faqs } = useFetchDocuments("faqs", null, null);
    const tableRef = useRef(null);
    useEffect(() => {
        if (faqs) {
            $(tableRef.current).DataTable({

                retrieve: true,
                dom: 'BCRSZlfrtip',
                buttons: [
                    'excelHtml5',
                    'autoWidth',
                    'pdf',
                    'csv'
                ]
            });
        }


    }, [faqs]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [itemToDelete, setItemToDelete] = useState('');

    const { deleteDocument } = useDeleteDocument("faqs");

    function ExcluirItemModal(itemId) {
        setItemToDelete(itemId)
        handleShow()

    };

    return (
        <div className='mt-3 mb-5'>
            <div className='container'>
                <div className='row'>
                    <div className='mx-auto col-md-4 text-center'>
                        <h2 >Faqs</h2>
                        <a href='/admin/faqs/create' className='btn btn-md btn-success mt-2 mb-2'>Criar Faqs</a>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 mx-auto'>
                        <table ref={tableRef} className="display stripe w-100" data-page-length="10" >
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {faqs &&
                                    faqs.map((faq) => (
                                        <tr key={faq.uid}>
                                            <td >
                                                <p>{faq.title}</p>
                                            </td>
                                            <td >
                                                <p>{faq.description}</p>
                                            </td>

                                            <td>
                                                <Link to={`/admin/faqs/update/${faq.id}`} className="btn btn-warning m-1">
                                                    Edit
                                                </Link>
                                                <button
                                                    onClick={() => ExcluirItemModal(faq.id)}

                                                    className="btn btn-outline btn-danger m-1"
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Danger!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <p>Do you really want to delete this item?</p>
                        <button
                            onClick={() => {
                                deleteDocument(itemToDelete)
                                handleClose()
                            }}
                            className="btn btn-outline btn-danger m-1"
                        >
                            Yes
                        </button>
                        <button onClick={() => handleClose()}
                            className="btn btn-outline btn-secondary m-1">
                            No
                        </button>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </div>
    );
};



export default Faqs;