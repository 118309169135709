import React from 'react';
import { useFetchDocuments } from "../../../hooks/useFetchDocuments";
import HTMLRenderer from '../../../components/HTMLRenderer';



const TermsAndConditions = () => {
    const { documents: terms } = useFetchDocuments("terms", null, null);


    return (
        <div className='container py-5 my-5'>
            <div className='section py-5 my-5'>
                {terms &&
                    terms.map((term) => (
                        <HTMLRenderer htmlContent={term.page1 + term.page2 + term.page3} />

                    ))}

            </div>
        </div>
    );
}


export default TermsAndConditions;