import React from 'react';
import { Image } from 'react-bootstrap';



const Why = () => {


    return (
        <div className='container-fluid p-0' >
            <div className='container-fluid ' style={{ backgroundImage: "url('/Images/bg1.jpg')", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                <div className='container' style={{ justifyContent: "center", display: "flex", flexDirection: "column", minHeight: "100%" }}>
                    <div className='row'>
                        <div className="col-md-6" style={{ justifyContent: "center", display: "flex", flexDirection: "column" }}>
                            <div style={{ marginRight: '10px' }}>
                                <h1 className style={{ color: "#32E7B4", fontWeight: "bold", }}>
                                    Why?	</h1>
                            </div>
                            <div>
                                <div >
                                    <h6 >As we believe in the power of human connections through language, our team is dedicated to breaking down linguistic and cultural barriers by inviting everyone to develop their confidence and ability to express themselves clearly in their chosen target language. Our mission is to unite people from diverse backgrounds and cultures foster authentic interactions, and promote learning, understanding and the broadening of horizons.We have created an inclusive app that celebrates language learners and encourages free expression.</h6>
                                    <h6 >Voxer will allow you to master your target language, opening doors to endless opportunities in careers, businesses, education, and personal life.</h6>
                                    <h6 > Join us on a journey of personal growth, self-confidence, and intercultural understanding. Join Voxer to break language barriers and connect you to the world!</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <Image className='img-fluid' src="/Images/phone_14_cam_01-4.png"></Image>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid py-5' style={{ backgroundImage: "url('/Images/h1-bg-img3.jpg')", backgroundSize: "cover", height: "600px", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                <div className='container' style={{ justifyContent: "center", display: "flex", flexDirection: "column", minHeight: "100%" }}>
                    <div className='row'>
                        <div className="col-md-4">
                            <div style={{ marginRight: '10px' }}>
                                <h1 className="qodef-m-title" style={{ color: "#32E7B4", fontWeight: "bold", }}>
                                    How?	</h1>
                            </div>
                            <div>
                                <div>
                                    <span style={{ fontSize: "17px", color: "white" }}>Through VOXER, we revolutionize the way you learn and interact with others in your target language. Our app utilizes an intelligent matching algorithm that takes into account your interests, language proficiency, and even psychological traits to connect you with like-minded language learners and native speakers who share similar interests. We guarantee privacy, personalization, and unforgettable experiences.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid py-5' style={{ backgroundImage: "url('/Images/bg2.jpg')", backgroundSize: "auto", backgroundRepeat: "no-repeat" }}>
                <div className='container' style={{ justifyContent: "center", display: "flex", flexDirection: "column", minHeight: "100%" }}>
                    <div className='row'>
                        <div className="col-md-6">
                            <Image className='img-fluid' style={{ height: "550px" }} src="/Images/iphone_5-1.png"></Image>
                        </div>
                        <div className="col-md-6" style={{ justifyContent: "center", display: "flex", flexDirection: "column" }}>
                            <div style={{ marginRight: '50px' }}>
                                <h1 style={{ color: "#32E7B4", fontWeight: "bold" }}>
                                    We are in the beginning of our journey.</h1>
                            </div>
                            <div>
                                <span style={{ fontSize: "17px" }}>Our app has been designed to connect the world through language practice and have you find the best language partner as well as a native speaker of the target language who see the world as you see it.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid py-5' style={{ backgroundImage: "url('/Images/bg4.jpg')", backgroundSize: "cover", height: "600px", backgroundRepeat: "no-repeat" }}>
                <div className='container' style={{ justifyContent: "center", display: "flex", flexDirection: "column", minHeight: "100%" }}>
                    <div className='row'>
                        <div className="col-md-6">
                            <div style={{ marginRight: '10px' }}>
                                <h1 className="qodef-m-title" style={{ color: "#3F3F5B", fontWeight: "bold" }}>
                                    Voxer connects you with those who get you!	</h1>
                            </div>
                        </div>
                        <div className="col-md-6" style={{ justifyContent: "center", display: "flex" }} >

                            <Image className='img-fluid p-2' src="/Images/bt_apple_store.svg"></Image>
                            <Image className='img-fluid p-2' src="/Images/bt_google_play.svg"></Image>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Why;