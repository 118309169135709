//import styles from "./Login.module.css";

import { useEffect, useState } from "react";
import { useAuthentication } from "../../../hooks/useAuthentication";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const { login, error: authError, loading } = useAuthentication();

    const handleSubmit = async (e) => {
        e.preventDefault();

        setError("");

        const user = {
            email,
            password,
        };

        await login(user);

    };

    useEffect(() => {
        if (authError) {
            setError(authError);
        }
    }, [authError]);

    return (
        <div className="container" >
            <div className="row" style={{ minHeight: '100vh' }}>
                <div className="mx-auto my-auto col-md-6">
                    <div className="card">
                        <div className="card-body text-center">
                            <h1>Enter</h1>
                            <p>Log in to be able to use the system</p>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-8 mx-auto my-2">
                                        <span>E-mail:</span>
                                        <input
                                            type="email"
                                            name="email"
                                            required
                                            placeholder="E-mail"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8 mx-auto my-2">
                                        <span>Senha:</span>
                                        <input
                                            type="password"
                                            name="password"
                                            required
                                            placeholder="Password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                            className="form-control"
                                        />
                                    </div>
                                </div>

                                {!loading && <button className="btn btn-md btn-success">Enter</button>}
                                {loading && (
                                    <button className="btn btn-md primary" disabled>
                                        Aguarde...
                                    </button>
                                )}
                                {error && <div className="mt-4 alert alert-danger" role="alert">{error}</div>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;