import React, { useState, useEffect } from 'react';

import { useUpdateDocument } from '../../../hooks/useUpdateDocument';
import { useFetchDocument } from '../../../hooks/useFetchDocument';
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../../../lotties/loading';

const QuestionsUpdate = () => {
    const { id } = useParams();
    const { document: objeto } = useFetchDocument("questions", id);
    const [CEFR, setCEFR] = useState("");
    const [title, setTitle] = useState("");
    const [question, setQuestion] = useState("");
    const [formError, setFormError] = useState("");

    // fill form data
    useEffect(() => {
        if (objeto) {
            setCEFR(objeto.CEFR);
            setQuestion(objeto.question);
            setTitle(objeto.title);
        }

    }, [objeto]);




    const navigate = useNavigate();

    const { updateDocument, response } = useUpdateDocument("questions");

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError("");

        // check values
        if (!CEFR || !question || !title) {
            setFormError("Por favor, preencha todos os campos!");
        }

        if (formError) return

        const data = {
            CEFR,
            question,
            title,

        };

        updateDocument(id, data);
    };
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className='mt-3 mb-5'>
            <div className='container bg-light py-5'>
                <div className='row'>
                    <div className='mx-auto col-md-4 text-center'>
                        <h2>Editando Questions:  </h2>{question}
                        <p>Altere os dados da Question como desejar</p>
                        {(response.success === true) && (
                            <div className="alert alert-success" role="alert">
                                Atualizado com sucesso
                            </div>
                        )

                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6 mx-auto card p-5'>
                        <form onSubmit={handleSubmit}>
                            <div className='mb-3'>
                                <label htmlFor="title" className="form-label">Title</label>
                                <input

                                    className='form-control'
                                    type="text"
                                    name="text"
                                    required
                                    placeholder="Title"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor="CEFR" className="form-label">CEFR</label>
                                <select
                                    className='form-select'
                                    type="text"
                                    name="text"
                                    required
                                    onChange={(e) => setCEFR(e.target.value)}
                                    value={CEFR}
                                >
                                    <option value="A1">A1</option>
                                    <option value="A2">A2</option>
                                    <option value="B1">B1</option>
                                    <option value="B2">B2</option>
                                    <option value="C1">C1</option>

                                </select>
                            </div>
                            <div className='mb-3'>
                                <label htmlFor="question" className="form-label">Question</label>
                                <textarea

                                    className='form-control'
                                    type="text-area"
                                    name="text"
                                    required
                                    placeholder="Question"
                                    onChange={(e) => setQuestion(e.target.value)}
                                    value={question}
                                />
                            </div>

                            {!response.loading && <button className="btn btn-md btn-success">Atualizar Question!</button>}
                            {response.loading && (
                                <button className="btn" disabled>
                                    <Lottie
                                        options={defaultOptions}
                                        height={400}
                                        width={400}
                                    />
                                </button>
                            )}
                            {(response.error || formError) && (
                                <p className="error">{response.error || formError}</p>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            {
                (response.success === true) && (
                    setTimeout(() => {
                        navigate("/admin/questions")
                    }, 2000)
                )
            }
        </div>
    );
};



export default QuestionsUpdate;