


const LayoutSite = ({ children }) => {
    return (
        <div>

            <div >
                {children}
            </div>
            <footer className="footer py-4">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 text-lg-start">Copyright &copy; 2023 Voxer APP, All Rights Reserved</div>
                        <div className="col-lg-4 my-3 my-lg-0 text-center">
                            <a className="btn btn-dark btn-social rounded-circle mx-2" target="_blank" href="https://www.linkedin.com/company/voxerglobal/" aria-label="Linkedin" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a>
                            <a className="btn btn-dark btn-social rounded-circle mx-2" target="_blank" href="https://www.facebook.com/share/sGdCkEyY9fHWAJ3S/?mibextid=qi2Omg" aria-label="Facebook" rel="noreferrer"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-dark btn-social rounded-circle mx-2" target="_blank" href="https://www.instagram.com/voxerglobal?igsh=MXZxbGV5dXg1anA4ZA==" aria-label="LinkedIn" rel="noreferrer"><i className="fab fa-instagram"></i></a>
                        </div>
                        <div className="col-lg-4 text-lg-end">
                            <a className="link-dark text-decoration-none me-3" href="/privacy-policy">Privacy Policy</a>
                            <a className="link-dark text-decoration-none" href="/terms-and-conditions">Terms of Use</a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )

}
export default LayoutSite

