import * as React from "react";
import { Routes, Route, Link, Navigate, useLocation } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";

//components
import LayoutAdmin from "./components/LayoutAdmin";
import LayoutSite from "./components/LayoutSite";
import LayoutLogin from "./components/LayoutLogin";

// hooks
import { useState, useEffect } from "react";
import { useAuthentication } from "./hooks/useAuthentication";

// context
import { AuthProvider } from "./contexts/AuthContext";

//pages
import PrivacyPolicy from "./modules/site/privacy-policy/PrivacyPolicy";
import TermsAndConditions from "./modules/site/terms-and-conditions/TermsAndConditions";
import Why from "./modules/site/why/Why";
import Login from "./modules/admin/login/Login";
import Dashboard from "./modules/admin/dashboard/Dashboard";
import Home from "./modules/site/home/Home";
import Words from "./modules/admin/words/Words";
import WordsCreate from "./modules/admin/words-create/WordsCreate";
import WordsUpdate from "./modules/admin/words-update/WordsUpdate";
import Contact from "./modules/site/contact/Contact";
import Terms from "./modules/admin/terms/Terms";
import TermsUpdate from "./modules/admin/terms-update/TermsUpdate";
import GoodPractice from "./modules/admin/good-practice/GoodPractice";
import GoodPracticeUpdate from "./modules/admin/good-practice-update/GoodPracticeUpdate";
import CodeConduct from "./modules/admin/code-conduct/CodeConduct";
import CodeConductUpdate from "./modules/admin/code-conduct-update/CodeConductUpdate";
import Preferences from "./modules/admin/preferences/Preferences";
import PreferencesUpdate from "./modules/admin/preferences-update/PreferencesUpdate";
import PreferencesCreate from "./modules/admin/preferences-create/PreferencesCreate";
import EvaluateCall from "./modules/admin/evaluate-call/EvaluateCall";
import EvaluateCallUpdate from "./modules/admin/evaluate-call-update/EvaluateCallUpdate";
import Faqs from "./modules/admin/faqs/Faqs";
import FaqsCreate from "./modules/admin/faqs-create/FaqsCreate";
import FaqsUpdate from "./modules/admin/faqs-update/FaqsUpdate";
import CallGroup from "./modules/admin/call-group/CallGroup";
import CallHistory from "./modules/admin/call-history/CallHistory";
import Improve from "./modules/admin/improve/Improve";
import ImproveUpdate from "./modules/admin/improve-update/ImproveUpdate";
import MainCommonPreferences from "./modules/admin/main-common-preferences/MainCommonPreferences";
import MainCommonPreferencesCreate from "./modules/admin/main-common-preferences-create/MainCommonPreferencesCreate";
import MainCommonPreferencesUpdate from "./modules/admin/main-common-preferences-update/MainCommonPreferencesUpdate";
import Questions from "./modules/admin/questions/Questions";
import QuestionsCreate from "./modules/admin/questions-create/QuestionsCreate";
import QuestionsUpdate from "./modules/admin/questions-update/QuestionsUpdate";
import Report from "./modules/admin/report/Report";
import ReportUpdate from "./modules/admin/report-update/ReportUpdate";
import ResultTest from "./modules/admin/result-test/ResultTest";
import ResultTestCreate from "./modules/admin/result-test-create/ResultTestCreate";
import ResultTestUpdate from "./modules/admin/result-test-update/ResultTestUpdate";
import Users from "./modules/admin/users/Users";
import UsersUpdate from "./modules/admin/users-update/UsersUpdate";
import GameWordList from "./modules/admin/game-word-list/GameWordList";
import GameWordListCreate from "./modules/admin/game-word-list-create/GameWordListCreate";
import GameWordListUpdate from "./modules/admin/game-word-list-update/GameWordListUpdate";
import Lottie from 'react-lottie';
import animationData from './lotties/loading';
import ListaInteresse from "./modules/admin/lista-interesse/ListaInteresse";
import Plans from "./modules/admin/plans/Plans";
import PlansCreate from "./modules/admin/plans-create/PlansCreate";
import PlansUpdate from "./modules/admin/plans-update/PlansUpdate";
import i18n from './i18n';
import { Suspense } from "react";
import LocaleContext from "./LocaleContext";
import { useTranslation } from 'react-i18next';
import JoinCall from "./modules/admin/join-call/JoinCall";



export default function App() {

  const [user, setUser] = useState(undefined);
  const { auth } = useAuthentication();
  const loadingUser = user === undefined;
  const location = useLocation();


  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
  }, [auth]);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const [locale, setLocale] = useState(i18n.language);

  i18n.on('languageChanged', (lng) => setLocale(i18n.language));

  const handleClick = (value) => {
    i18n.changeLanguage(value)
  }

  const { t } = useTranslation();


  if (loadingUser) {
    return <Lottie
      options={defaultOptions}
      height={400}
      width={400}
    />;
  }

  function Loading() {
    return <Lottie
      options={defaultOptions}
      height={400}
      width={400}
    />;
  }

  return (
    <AuthProvider value={{ user }}>

      < Routes >
        <Route path="/" >
          <Route index element={
            <LocaleContext.Provider value={(locale, setLocale)}>
              <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
                <div className="container px-5">
                  <a className="navbar-brand" href="/#page-top"><img src="Images/Asset-2.png" className="img-fluid" alt="..." /></a>
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                    <i className="bi-list"></i>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
                      <li className="nav-item"><a className="nav-link me-lg-3" style={{ fontSize: "1.8rem", color: "#302A94", borderBottom: (location.pathname === '/') ? "3px solid #32E7B4" : "" }} href="/#download">{t('download')}</a></li>
                      <li className="nav-item"><a className="nav-link me-lg-3" style={{ fontSize: "1.8rem", color: "#302A94", borderBottom: (location.pathname === '/contact') ? "3px solid #32E7B4" : "" }} href="/contact">{t('contact')}</a></li>
                      <img onClick={() => handleClick('en')} alt="bandeira-eua" className="m-2" style={{ height: "30px", width: "30px" }} src="/img/estados-unidos.png"></img>
                      <img onClick={() => handleClick('es')} alt="bandeira-espanha" className="m-2" style={{ height: "30px", width: "30px" }} src="/img/espanha.png"></img>

                    </ul>
                  </div>
                </div>
              </nav>

              <Suspense fallback={<Loading />}>
                <LayoutSite>

                  <Home />

                </LayoutSite>
              </Suspense>
            </LocaleContext.Provider>
          } />
        </Route>
        <Route path="/privacy-policy" >
          <Route index element={<LocaleContext.Provider value={(locale, setLocale)}>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
              <div className="container px-5">
                <a className="navbar-brand" href="/#page-top"><img src="Images/Asset-2.png" className="img-fluid" alt="..." /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                  Menu
                  <i className="bi-list"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                  <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
                    <li className="nav-item"><a className="nav-link me-lg-3" style={{ fontSize: "1.8rem", color: "#302A94", borderBottom: (location.pathname === '/') ? "3px solid #32E7B4" : "" }} href="/#download">{t('download')}</a></li>
                    <li className="nav-item"><a className="nav-link me-lg-3" style={{ fontSize: "1.8rem", color: "#302A94", borderBottom: (location.pathname === '/contact') ? "3px solid #32E7B4" : "" }} href="/contact">{t('contact')}</a></li>
                    <img onClick={() => handleClick('en')} alt="bandeira-eua" className="m-2" style={{ height: "30px", width: "30px" }} src="/img/estados-unidos.png"></img>
                    <img onClick={() => handleClick('es')} alt="bandeira-espanha" className="m-2" style={{ height: "30px", width: "30px" }} src="/img/espanha.png"></img>

                  </ul>
                </div>
              </div>
            </nav>

            <Suspense fallback={<Loading />}>
              <LayoutSite><PrivacyPolicy /> </LayoutSite>
            </Suspense>
          </LocaleContext.Provider>
          } />
        </Route>
        <Route path="/user" >
          <Route index element={<LocaleContext.Provider value={(locale, setLocale)}>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
              <div className="container px-5">
                <a className="navbar-brand" href="/#page-top"><img src="Images/Asset-2.png" className="img-fluid" alt="..." /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                  Menu
                  <i className="bi-list"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                  <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
                    <li className="nav-item"><a className="nav-link me-lg-3" style={{ fontSize: "1.8rem", color: "#302A94", borderBottom: (location.pathname === '/') ? "3px solid #32E7B4" : "" }} href="/#download">{t('download')}</a></li>
                    <li className="nav-item"><a className="nav-link me-lg-3" style={{ fontSize: "1.8rem", color: "#302A94", borderBottom: (location.pathname === '/contact') ? "3px solid #32E7B4" : "" }} href="/contact">{t('contact')}</a></li>
                    <img onClick={() => handleClick('en')} alt="bandeira-eua" className="m-2" style={{ height: "30px", width: "30px" }} src="/img/estados-unidos.png"></img>
                    <img onClick={() => handleClick('es')} alt="bandeira-espanha" className="m-2" style={{ height: "30px", width: "30px" }} src="/img/espanha.png"></img>

                  </ul>
                </div>
              </div>
            </nav>

            <Suspense fallback={<Loading />}>
              <LayoutSite><PrivacyPolicy /> </LayoutSite>
            </Suspense>
          </LocaleContext.Provider>
          } />
        </Route>
        <Route path="/terms-and-conditions" >
          <Route index element={<LocaleContext.Provider value={(locale, setLocale)}>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
              <div className="container px-5">
                <a className="navbar-brand" href="/#page-top"><img src="Images/Asset-2.png" className="img-fluid" alt="..." /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                  Menu
                  <i className="bi-list"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                  <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
                    <li className="nav-item"><a className="nav-link me-lg-3" style={{ fontSize: "1.8rem", color: "#302A94", borderBottom: (location.pathname === '/') ? "3px solid #32E7B4" : "" }} href="/#download">{t('download')}</a></li>
                    <li className="nav-item"><a className="nav-link me-lg-3" style={{ fontSize: "1.8rem", color: "#302A94", borderBottom: (location.pathname === '/contact') ? "3px solid #32E7B4" : "" }} href="/contact">{t('contact')}</a></li>
                    <img onClick={() => handleClick('en')} alt="bandeira-eua" className="m-2" style={{ height: "30px", width: "30px" }} src="/img/estados-unidos.png"></img>
                    <img onClick={() => handleClick('es')} alt="bandeira-espanha" className="m-2" style={{ height: "30px", width: "30px" }} src="/img/espanha.png"></img>

                  </ul>
                </div>
              </div>
            </nav>

            <Suspense fallback={<Loading />}>
              <LayoutSite><TermsAndConditions /></LayoutSite>
            </Suspense>
          </LocaleContext.Provider>} />
        </Route>
        <Route path="/why" >
          <Route index element={<LocaleContext.Provider value={(locale, setLocale)}>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
              <div className="container px-5">
                <a className="navbar-brand" href="/#page-top"><img src="Images/Asset-2.png" className="img-fluid" alt="..." /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                  Menu
                  <i className="bi-list"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                  <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
                    <li className="nav-item"><a className="nav-link me-lg-3" style={{ fontSize: "1.8rem", color: "#302A94", borderBottom: (location.pathname === '/') ? "3px solid #32E7B4" : "" }} href="/#download">{t('download')}</a></li>
                    <li className="nav-item"><a className="nav-link me-lg-3" style={{ fontSize: "1.8rem", color: "#302A94", borderBottom: (location.pathname === '/contact') ? "3px solid #32E7B4" : "" }} href="/contact">{t('contact')}</a></li>
                    <img onClick={() => handleClick('en')} alt="bandeira-eua" className="m-2" style={{ height: "30px", width: "30px" }} src="/img/estados-unidos.png"></img>
                    <img onClick={() => handleClick('es')} alt="bandeira-espanha" className="m-2" style={{ height: "30px", width: "30px" }} src="/img/espanha.png"></img>

                  </ul>
                </div>
              </div>
            </nav>

            <Suspense fallback={<Loading />}>
              <LayoutSite><Why /></LayoutSite>
            </Suspense>
          </LocaleContext.Provider>} />
        </Route>
        <Route path="/contact" >
          <Route index element={<LocaleContext.Provider value={(locale, setLocale)}>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
              <div className="container px-5">
                <a className="navbar-brand" href="/#page-top"><img src="Images/Asset-2.png" className="img-fluid" alt="..." /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                  Menu
                  <i className="bi-list"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                  <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
                    <li className="nav-item"><a className="nav-link me-lg-3" style={{ fontSize: "1.8rem", color: "#302A94", borderBottom: (location.pathname === '/') ? "3px solid #32E7B4" : "" }} href="/#download">{t('download')}</a></li>
                    <li className="nav-item"><a className="nav-link me-lg-3" style={{ fontSize: "1.8rem", color: "#302A94", borderBottom: (location.pathname === '/contact') ? "3px solid #32E7B4" : "" }} href="/contact">{t('contact')}</a></li>
                    <img onClick={() => handleClick('en')} alt="bandeira-eua" className="m-2" style={{ height: "30px", width: "30px" }} src="/img/estados-unidos.png"></img>
                    <img onClick={() => handleClick('es')} alt="bandeira-espanha" className="m-2" style={{ height: "30px", width: "30px" }} src="/img/espanha.png"></img>

                  </ul>
                </div>
              </div>
            </nav>

            <Suspense fallback={<Loading />}>
              <LayoutSite><Contact /></LayoutSite>
            </Suspense>
          </LocaleContext.Provider>} />
        </Route>
        <Route path="/admin" >
          <Route path="/admin/" element={!user ? <LayoutLogin><Login /></LayoutLogin> : <Navigate to="/admin/dashboard" />} />
          <Route path="/admin/dashboard" element={user ? <LayoutAdmin><Dashboard /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/words" element={user ? <LayoutAdmin><Words /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/words/create" element={user ? <LayoutAdmin><WordsCreate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/words/update/:id" element={user ? <LayoutAdmin><WordsUpdate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/terms" element={user ? <LayoutAdmin><Terms /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/terms/update/:id" element={user ? <LayoutAdmin><TermsUpdate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/good-practice" element={user ? <LayoutAdmin><GoodPractice /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/good-practice/update/:id" element={user ? <LayoutAdmin><GoodPracticeUpdate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/code-conduct" element={user ? <LayoutAdmin><CodeConduct /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/code-conduct/update/:id" element={user ? <LayoutAdmin><CodeConductUpdate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/preferences" element={user ? <LayoutAdmin><Preferences /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/preferences/update/:id" element={user ? <LayoutAdmin><PreferencesUpdate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/preferences/create" element={user ? <LayoutAdmin><PreferencesCreate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/game-word-list" element={user ? <LayoutAdmin><GameWordList /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/game-word-list/update/:id" element={user ? <LayoutAdmin><GameWordListUpdate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/game-word-list/create" element={user ? <LayoutAdmin><GameWordListCreate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/evaluate-call" element={user ? <LayoutAdmin><EvaluateCall /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/evaluate-call/update/:id" element={user ? <LayoutAdmin><EvaluateCallUpdate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/evaluate-call/create" element={user ? <LayoutAdmin><EvaluateCallUpdate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/faqs" element={user ? <LayoutAdmin><Faqs /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/faqs/create" element={user ? <LayoutAdmin><FaqsCreate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/faqs/update/:id" element={user ? <LayoutAdmin><FaqsUpdate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/call-group/" element={user ? <LayoutAdmin><CallGroup /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/call-history/" element={user ? <LayoutAdmin><CallHistory /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/improve/" element={user ? <LayoutAdmin><Improve /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/improve/update/:id" element={user ? <LayoutAdmin><ImproveUpdate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/main-common-preferences" element={user ? <LayoutAdmin><MainCommonPreferences /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/main-common-preferences/create" element={user ? <LayoutAdmin><MainCommonPreferencesCreate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/main-common-preferences/update/:id" element={user ? <LayoutAdmin><MainCommonPreferencesUpdate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/questions" element={user ? <LayoutAdmin><Questions /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/questions/create" element={user ? <LayoutAdmin><QuestionsCreate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/questions/update/:id" element={user ? <LayoutAdmin><QuestionsUpdate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/report/" element={user ? <LayoutAdmin><Report /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/report/update/:id" element={user ? <LayoutAdmin><ReportUpdate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/result-test" element={user ? <LayoutAdmin><ResultTest /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/result-test/create" element={user ? <LayoutAdmin><ResultTestCreate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/result-test/update/:id" element={user ? <LayoutAdmin><ResultTestUpdate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/plans" element={user ? <LayoutAdmin><Plans /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/plans/create" element={user ? <LayoutAdmin><PlansCreate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/plans/update/:id" element={user ? <LayoutAdmin><PlansUpdate /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/lista-interesse" element={user ? <LayoutAdmin><ListaInteresse /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/join-call" element={user ? <LayoutAdmin><JoinCall /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/users" element={user ? <LayoutAdmin><Users /></LayoutAdmin> : <Navigate to="/admin" />} />
          <Route path="/admin/users/update/:id" element={user ? <LayoutAdmin><UsersUpdate /></LayoutAdmin> : <Navigate to="/admin" />} />

          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes >
    </AuthProvider >
  );
}


function NoMatch() {
  return (
    <div>
      <h2>Erro 404</h2>
      <p> Pagina não encontrada</p>
      <p>
        <Link to="/">Voltar tela inicial</Link>
      </p>
    </div>
  );
}