import React, { useState, useEffect } from 'react';
import { useUpdateDocument } from '../../../hooks/useUpdateDocument';
import { useFetchDocument } from '../../../hooks/useFetchDocument';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Lottie from 'react-lottie';
import animationData from '../../../lotties/loading';

const PreferencesUpdate = () => {
    const { id } = useParams();
    const { document: preferences } = useFetchDocument("preferences", id);
    const [categorie, setCategorie] = useState("");
    const [formError, setFormError] = useState("");

    // fill form data
    useEffect(() => {
        if (preferences) {
            setList(preferences.preferences);
            setCategorie(preferences.categorie);
        }
    }, [preferences]);



    const navigate = useNavigate();

    const { updateDocument, response } = useUpdateDocument("preferences");

    const [list, setList] = useState([])
    const [newItemText, setNewItemText] = useState('');
    const [editItemId, setEditItemId] = useState(null);
    const [editItemText, setEditItemText] = useState('');
    const addItem = () => {
        const newItem = {
            title: newItemText,
            score: 0.0
        };
        setList([...list, newItem]);
        setNewItemText('');
    };

    const removeItem = (title) => {
        const updatedList = list.filter(item => item.title !== title);
        setList(updatedList);
    };

    const editItem = (title) => {
        const itemToEdit = list.find(item => item.title === title);

        if (itemToEdit) {
            setEditItemId(itemToEdit.title);
            setEditItemText(itemToEdit.title);
            ExcluirItemModal(itemToEdit.title)
        }
    };
    const updateItem = () => {
        const updatedList = list.map(item => {
            if (item.title === editItemId) {
                return {
                    ...item,
                    title: editItemText,
                    score: 0
                };
            }
            return item;
        });
        setList(updatedList);
        setEditItemId(null);
        setEditItemText('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError("");

        // check values
        if (!categorie) {
            setFormError("Por favor, preencha todos os campos!");
        }

        if (formError) return

        const data = {
            categorie,
            preferences: list
        };

        updateDocument(id, data);
    };

    function ExcluirItemModal(itemId) {
        setItemToDelete(itemId)
        handleShow()

    };


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [, setItemToDelete] = useState('');

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className='mt-3 mb-5'>
            <div className='container bg-light py-5'>
                <div className='row'>
                    <div className='mx-auto col-md-4 text-center'>
                        <h2>Editando Preferences: {categorie} </h2>
                        <p>Altere os dados do Preferences como desejar</p>
                        {(response.success === true) && (
                            <div className="alert alert-success" role="alert">
                                Atualizado com sucesso
                            </div>
                        )

                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6 mx-auto card p-5'>
                        <form onSubmit={handleSubmit}>
                            <div className='card p-4 mb-3'>
                                <h4>Adicionar Preferencia</h4>
                                <div className='mb-3'>
                                    <label htmlFor="title" className="form-label">Title</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        placeholder="Texto"
                                        value={newItemText}
                                        onChange={(e) => setNewItemText(e.target.value)}
                                    />
                                    <div className='btn btn-md btn-success mt-3' onClick={addItem}>Adicionar</div>
                                </div>
                            </div>

                            <div className="accordion mb-3" id="accordionExample">
                                {list && list.map(item => (
                                    <div className="accordion-item" key={item.title.replace(/[^a-zA-Z0-9]/g, '')}>
                                        <h2 className="accordion-header" id={'heading' + item.title.replace(/[^a-zA-Z0-9]/g, '')}>
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={'#collapse' + item.title.replace(/[^a-zA-Z0-9]/g, '')} aria-expanded="false" aria-controls={'collapse' + item.title.replace(/[^a-zA-Z0-9]/g, '')}>
                                                {item.title}
                                            </button>
                                        </h2>
                                        <div id={'collapse' + item.title.replace(/[^a-zA-Z0-9]/g, '')} className="accordion-collapse collapse" aria-labelledby={'heading' + item.title.replace(/[^a-zA-Z0-9]/g, '')} data-bs-parent="#accordionExample">
                                            <div className="accordion-body">

                                                {item.title}
                                                <div className='justify-content-around d-flex'>
                                                    <div className='btn btn-md btn-danger' onClick={() => removeItem(item.title)}>Delete</div>
                                                    <div className='btn btn-md btn-warning' onClick={() => editItem(item.title)}>Edit</div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </div>
                            {editItemId !== null &&
                                <Modal show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Atualizar!</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='container'>
                                            <div className='card p-4 mb-3'>
                                                <h2>Edit Item</h2>
                                                <input
                                                    className='form-control mb-3'
                                                    type="text"
                                                    placeholder="Texto"
                                                    value={editItemText}
                                                    onChange={(e) => setEditItemText(e.target.value)}
                                                />
                                                <div className='btn btn-md btn-success' onClick={updateItem}>Atualizar</div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>

                                    </Modal.Footer>
                                </Modal>}

                            {!response.loading && <button className="btn btn-md btn-success">Atualizar Preferences!</button>}
                            {response.loading && (
                                <button className="btn" disabled>
                                    <Lottie
                                        options={defaultOptions}
                                        height={400}
                                        width={400}
                                    />
                                </button>
                            )}
                            {(response.error || formError) && (
                                <p className="error">{response.error || formError}</p>
                            )}
                        </form>
                    </div>
                </div>

            </div>
            {
                (response.success === true) && (
                    setTimeout(() => {
                        navigate("/admin/preferences")
                    }, 2000)
                )
            }
        </div>
    );
};



export default PreferencesUpdate;