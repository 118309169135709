import React from 'react';
import { useFetchDocuments } from "../../../hooks/useFetchDocuments";
import HTMLRenderer from '../../../components/HTMLRenderer';



const PrivacyPolicy = () => {
    const { documents: codeConduct } = useFetchDocuments("code-conduct", null, null);


    return (
        <div className='container py-5 my-5'>
            <div className='section py-5 my-5'>
                {codeConduct &&
                    codeConduct.map((value) => (
                        <HTMLRenderer htmlContent={value.page1 + value.page2 + value.page3} />
                    ))}
            </div>
        </div>
    );
}


export default PrivacyPolicy;