import React, { useState, useEffect } from 'react';
import { useUpdateDocument } from '../../../hooks/useUpdateDocument';
import { useFetchDocument } from '../../../hooks/useFetchDocument';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Lottie from 'react-lottie';
import animationData from '../../../lotties/loading';

const MainCommonPreferencesUpdate = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { document: mainCommonPreferences } = useFetchDocument("main-common-preferences", id);
    const { updateDocument, response } = useUpdateDocument("main-common-preferences");
    const [title, setTitle] = useState("");
    const [formError, setFormError] = useState("");
    const [listPrefI, setListPrefI] = useState([])
    const [listPrefQ, setListPrefQ] = useState([])
    const [newInformationText, setNewInformationText] = useState('');
    const [newAnswer, setNewAnswer] = useState('');
    const [newQuestion, setNewQuestion] = useState('');
    const [editItemIId, setEditItemIId] = useState(null);
    const [editItemQId, setEditItemQId] = useState(null);
    const [editItemIText, setEditItemIText] = useState('');
    const [editNewAnswer, setEditNewAnswer] = useState('');
    const [editNewQuestion, setEditNewQuestion] = useState('');

    // fill form data
    useEffect(() => {
        if (mainCommonPreferences) {
            if (mainCommonPreferences.preferenceInformations) {
                setListPrefI(mainCommonPreferences.preferenceInformations);

            }
            if (mainCommonPreferences.preferenceQuestions) {
                setListPrefQ(mainCommonPreferences.preferenceQuestions);
            }
            setTitle(mainCommonPreferences.title);
        }
    }, [mainCommonPreferences]);

    const addItemI = () => {
        setListPrefI([...listPrefI, newInformationText]);
        setNewInformationText('');
    };


    const addItemQ = () => {
        const newItem = {
            preferenceQuestion: {
                answer: newAnswer,
                question: newQuestion
            }
        };
        setListPrefQ([...listPrefQ, newItem]);
        setNewAnswer('');
        setNewQuestion('');
    };

    const removeItemI = (title) => {
        const updatedList = listPrefI.filter(item => item !== title);
        setListPrefI(updatedList);
    };

    const removeItemQ = (question) => {
        const updatedList = listPrefQ.filter(item => item.preferenceQuestion.question !== question);
        setListPrefQ(updatedList);
    };

    const editItemI = (title) => {
        const itemToEdit = listPrefI.find(item => item === title);
        if (itemToEdit) {
            setEditItemIId(itemToEdit);
            setEditItemIText(itemToEdit);
            ExcluirItemModal(itemToEdit.title)
        }
    };

    function ExcluirItemModal(itemId) {
        setItemToDelete(itemId)
        handleShow()

    };

    const editItemQ = (question) => {

        const itemToEdit = listPrefQ.find(item => item.preferenceQuestion.question === question);
        if (itemToEdit) {
            setEditItemQId(itemToEdit.preferenceQuestion.question);
            setEditNewAnswer(itemToEdit.preferenceQuestion.answer);
            setEditNewQuestion(itemToEdit.preferenceQuestion.question);
            ExcluirItemModal(question)
        }
    };

    const updateItemI = () => {
        const updatedList = listPrefI.map(item => {

            if (item === editItemIId) {
                return editItemIText;
            }

            return item;
        });

        setListPrefI(updatedList);
        setEditItemIId(null);
        setEditItemIText('');
    };

    const updateItemQ = () => {
        const updatedList = listPrefQ.map(item => {

            if (item.preferenceQuestion.question === editItemQId) {

                return {
                    ...item,
                    preferenceQuestion: {
                        question: editNewQuestion,
                        answer: editNewAnswer
                    }
                };
            }
            return item;
        });
        setListPrefQ(updatedList);
        setEditItemQId(null);
        setEditNewQuestion('');
        setEditNewAnswer('');
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError("");

        // check values
        if (!title) {
            setFormError("Por favor, preencha todos os campos!");
        }

        if (formError) return

        const data = {
            title,
            preferenceInformations: listPrefI,
            preferenceQuestions: listPrefQ
        };

        updateDocument(id, data);
    };

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
        setEditItemQId(null);
        setEditItemIId(null);

    };
    const handleShow = () => setShow(true);
    const [, setItemToDelete] = useState('');

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className='mt-3 mb-5'>
            <div className='container bg-light py-5'>
                <div className='row'>
                    <div className='mx-auto col-md-4 text-center'>
                        <h2>Editando Common Preferences: {title} </h2>
                        <p>Altere os dados do Preferences como desejar</p>
                        {(response.success === true) && (
                            <div className="alert alert-success" role="alert">
                                Atualizado com sucesso
                            </div>
                        )

                        }
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-md-5 card p-5 mx-auto'>


                            <div className='card p-4 mb-3'>
                                <h4>Adicionar Main Common Preferences Information</h4>
                                <div className='mb-3'>
                                    <label htmlFor="title" className="form-label">Title</label>
                                    <textarea
                                        type="text"
                                        className='form-control'
                                        placeholder="Texto"
                                        value={newInformationText}
                                        onChange={(e) => setNewInformationText(e.target.value)}
                                    />
                                    <div className='btn btn-md btn-success mt-3' onClick={addItemI}>Adicionar</div>
                                </div>
                            </div>

                            <div>
                                {listPrefI.length > 1 ?
                                    <h4> Main Common Preferences Information</h4> : ""
                                }

                                {listPrefI && listPrefI.map(item => (

                                    <div className='card p-5 mb-3' key={item}>
                                        {item}
                                        <div className='justify-content-around d-flex mt-3'>
                                            <div className='btn btn-md btn-danger' onClick={() => removeItemI(item)}>Delete</div>
                                            <div className='btn btn-md btn-warning' onClick={() => editItemI(item)}>Edit</div>
                                        </div>
                                    </div>

                                ))}
                            </div>






                            {!response.loading && <button className="btn btn-md btn-success">Atualizar Main Common Preferences!</button>}
                            {response.loading && (
                                <button className="btn" disabled>
                                    <Lottie
                                        options={defaultOptions}
                                        height={400}
                                        width={400}
                                    />
                                </button>
                            )}
                            {(response.error || formError) && (
                                <p className="error">{response.error || formError}</p>
                            )}

                        </div>
                        <div className='col-md-5 card p-5 mx-auto'>
                            <div className='card p-4 mb-3'>
                                <h4>Adicionar Main Common Preferences Questions</h4>
                                <div className='mb-3'>
                                    <label htmlFor="question" className="form-label">Question</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        placeholder="Texto"
                                        value={newQuestion}
                                        onChange={(e) => setNewQuestion(e.target.value)}
                                    />
                                    <label htmlFor="answer" className="form-label">Answer</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        placeholder="Texto"
                                        value={newAnswer}
                                        onChange={(e) => setNewAnswer(e.target.value)}
                                    />
                                    <div className='btn btn-md btn-success mt-3' onClick={addItemQ}>Adicionar</div>
                                </div>
                            </div>




                            {listPrefQ.length > 1 ?
                                <h4> Main Common Preferences Questions</h4> : ""
                            }
                            <div className="accordion mb-3" id="accordionExample">
                                {listPrefQ && listPrefQ.map(item => (
                                    <div className="accordion-item" key={item.preferenceQuestion.question.replace(/[^a-zA-Z0-9]/g, '')}>
                                        <h2 className="accordion-header" id={'heading' + item.preferenceQuestion.question.replace(/[^a-zA-Z0-9]/g, '')}>
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={'#collapse' + item.preferenceQuestion.question.replace(/[^a-zA-Z0-9]/g, '')} aria-expanded="false" aria-controls={'collapse' + item.preferenceQuestion.question.replace(/[^a-zA-Z0-9]/g, '')}>
                                                {item.preferenceQuestion.question}
                                            </button>
                                        </h2>
                                        <div id={'collapse' + item.preferenceQuestion.question.replace(/[^a-zA-Z0-9]/g, '')} className="accordion-collapse collapse" aria-labelledby={'heading' + item.preferenceQuestion.question.replace(/[^a-zA-Z0-9]/g, '')} data-bs-parent="#accordionExample">
                                            <div className="accordion-body">

                                                {item.preferenceQuestion.question} - {item.preferenceQuestion.answer}
                                                <div className='justify-content-around d-flex'>
                                                    <div className='btn btn-md btn-danger' onClick={() => removeItemQ(item.preferenceQuestion.question)}>Delete</div>
                                                    <div className='btn btn-md btn-warning' onClick={() => editItemQ(item.preferenceQuestion.question)}>Edit</div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </div>

                            {(editItemQId !== null || editItemIId !== null) && (
                                <Modal className='modal-lg' show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Atualizar!</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='container'>
                                            {editItemIId !== null && (

                                                <div className='card p-4 mb-3'>
                                                    <h2>Edit Item</h2>
                                                    <textarea
                                                        className='form-control mb-3'
                                                        type="text"
                                                        placeholder="Texto"
                                                        value={editItemIText}
                                                        onChange={(e) => setEditItemIText(e.target.value)}
                                                    />
                                                    <div className='btn btn-md btn-success' onClick={updateItemI}>Atualizar</div>
                                                </div>

                                            )}
                                            {editItemQId !== null && (
                                                <div className='card p-4 mb-3'>
                                                    <h2>Edit Item</h2>
                                                    <label htmlFor="question" className="form-label">Question</label>
                                                    <input
                                                        type="text"
                                                        className='form-control'
                                                        placeholder="Texto"
                                                        value={editNewQuestion}
                                                        onChange={(e) => setEditNewQuestion(e.target.value)}
                                                    />
                                                    <label htmlFor="answer" className="form-label">Answer</label>
                                                    <input
                                                        type="text"
                                                        className='form-control'
                                                        placeholder="Texto"
                                                        value={editNewAnswer}
                                                        onChange={(e) => setEditNewAnswer(e.target.value)}
                                                    />
                                                    <div className='btn btn-md btn-success mt-3' onClick={updateItemQ}>Atualizar</div>
                                                </div>
                                            )}

                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>

                                    </Modal.Footer>
                                </Modal>
                            )}
                        </div>

                    </div>
                </form>

            </div >
            {
                (response.success === true) && (
                    setTimeout(() => {
                        navigate("/admin/main-common-preferences")
                    }, 2000)
                )
            }
        </div >
    );
};



export default MainCommonPreferencesUpdate;