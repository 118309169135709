import { useReducer } from "react";
import { db } from "../firebase/config";
import {
    updateDoc, doc, getDoc
} from "firebase/firestore";


const initialState = {
    loading: null,
    error: null,
    success: null
};

let reducerActions = {
    "LOADING": (state, action) => {
        return { loading: true, error: null, success: null };
    },
    "UPDATED_DOC": (state, action) => {
        return { loading: false, error: null, success: true };
    },
    "ERROR": (state, action) => {
        return { loading: false, error: action.payload, success: null };
    }
};

function reducer(state, action) {
    let fn = reducerActions[action.type];

    if (fn) {
        return fn(state, action);
    }
    console.log('[WARNING] Action without reducer:', action);
    return state;
}


export const useUpdateDocument = (docCollection) => {
    const [response, dispatch] = useReducer(reducer, initialState);

    const updateDocument = async (uid, data) => {

        dispatch({ type: "LOADING" });

        try {
            var docRef = await doc(db, docCollection, uid);
            dispatch({
                type: "UPDATED_DOC",
            });
            await updateDoc(docRef, data);

            //TODO: Busca Lista de usuarios e atualiza com o campo verdadeiro
            if (docCollection === 'preferences') {
                const docStatus = await doc(db, 'app', 'status');
                const docSnap = await getDoc(docStatus);
                var obj = {}
                obj = docSnap.data();
                obj.preferecensVersion = obj.preferecensVersion + 1;

                await updateDoc(docStatus, obj);

            }

        } catch (error) {
            dispatch({ type: "ERROR", payload: error.message });
        }
    };


    return { updateDocument, response };
};