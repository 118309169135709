import React, { useState } from 'react';
import { useInsertDocument } from '../../../hooks/useInsertDocument';
import { Timestamp } from 'firebase/firestore';
import Lottie from 'react-lottie';
import animationData from '../../../lotties/loading';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';


const Contact = () => {
    const { t } = useTranslation();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [option, setOption] = useState("");
    const [message, setMessage] = useState("");
    const [formError, setFormError] = useState("");
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);


    const { insertDocument, response } = useInsertDocument("lista-interesse");

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError("");

        // check values
        if (!name || !email || !option) {
            setFormError("Por favor, preencha todos os campos!");
        }

        if (formError) return

        insertDocument({
            name,
            email,
            phone,
            option,
            message,
            createdAt: Timestamp.now(),
            uid: new Date().getTime()
        });

        setName('')
        setEmail('')
        setPhone('')
        setOption('')
        setMessage('')

    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };


    return (
        <div>
            <section className="page-section" id="contact">
                <div className="container">
                    <div className="row align-items-stretch mb-5 pt-5">
                        <div className="col-sm-12 col-md-6">
                            <h2 className="section-heading text-uppercase text-white" >{t('contactTitle')}</h2>
                            <h3 className="section-heading text-uppercase text-white" >{t('contactSubTitle')}</h3>
                            <p className="text-white " style={{ fontSize: "20px", whiteSpace: 'pre-line' }}>
                                {t('contactBody')}
                                <br /><br />

                                {t('contactBody1')}
                                <br /><br />
                                {t('contactBody2')}
                                <br /><br />
                                {t('contactBody3')}
                            </p>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <form id="contactForm" onSubmit={handleSubmit}>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <input
                                                    className="form-control"
                                                    id="name"
                                                    type="text"
                                                    placeholder={t('contactName')}
                                                    required
                                                    onChange={(e) => setName(e.target.value)}
                                                    value={name}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    className="form-control"
                                                    id="email"
                                                    type="email"
                                                    placeholder={t('contactEmail')}
                                                    required
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    value={email}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    className="form-control"
                                                    id="phone"
                                                    type="tel"
                                                    placeholder={t('contactPhone')}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    value={phone}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="form-control"
                                                    name="select"
                                                    required
                                                    onChange={(e) => setOption(e.target.value)}
                                                    value={option}
                                                >
                                                    <option value="">{t('contactChooseOption')}</option>
                                                    <option value="android">Android</option>
                                                    <option value="ios">IOS</option>
                                                </select>
                                            </div>
                                            <div className="form-group form-group-textarea mb-md-0">
                                                <textarea
                                                    className="form-control"
                                                    id="message"
                                                    placeholder={t('contactMessage')}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    value={message}>
                                                </textarea>
                                            </div>
                                        </div>
                                        <div className="text-center pt-3">
                                            {!response.loading && <button style={{ background: "#32E7B4", borderColor: "#32E7B4", color: "white" }} className="btn btn-xl text-uppercase" id="submitButton" type="submit">{t('contactSendMessage')}</button>}
                                            {(response.success === true) && (

                                                <Modal show={show} onHide={handleClose} size='lg' centered>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Voxer!</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className='container'>
                                                            <p>{t('contactResult')}</p>


                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <button style={{ background: "#32E7B4", borderColor: "#32E7B4", color: "white" }} onClick={() => handleClose()}
                                                            className="btn btn-outline m-1">
                                                            Ok
                                                        </button>
                                                    </Modal.Footer>
                                                </Modal>
                                            )

                                            }
                                            {response.loading && (
                                                <button className="btn" disabled>
                                                    <Lottie
                                                        options={defaultOptions}
                                                        height={400}
                                                        width={400}
                                                    />
                                                </button>
                                            )}
                                            {(response.error || formError) && (
                                                <p className="error">{response.error || formError}</p>
                                            )}
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            </section >
        </div >

    );
};


export default Contact;