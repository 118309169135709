import { useReducer } from "react";
import { db } from "../firebase/config";
import { collection, addDoc } from "firebase/firestore";

const initialState = {
    loading: null,
    error: null,
    success: null
};



let reducerActions = {
    "LOADING": (state, action) => {
        return { loading: true, error: null, success: null };
    },
    "INSERTED_DOC": (state, action) => {
        return { loading: false, error: null, success: true };
    },
    "ERROR": (state, action) => {
        return { loading: false, error: action.payload, success: null };
    }
};

function reducer(state, action) {
    let fn = reducerActions[action.type];

    if (fn) {
        return fn(state, action);
    }
    console.log('[WARNING] Action without reducer:', action);
    return state;
}

export const useInsertDocument = (docCollection) => {
    const [response, dispatch] = useReducer(reducer, initialState);

    const insertDocument = async (document) => {
        dispatch({ type: "LOADING" });

        try {
            const newDocument = { ...document };
            const insertedDocument = await addDoc(
                collection(db, docCollection),
                newDocument
            );

            dispatch({
                type: "INSERTED_DOC",
                payload: insertedDocument,
            });
        } catch (error) {
            dispatch({ type: "ERROR", payload: error.message });
        }
    };
    return { insertDocument, response };
};