import { useAuthentication } from "../hooks/useAuthentication";


const LayoutAdmin = ({ children }) => {

    const { logout } = useAuthentication();




    return (
        <div>
            <nav className="navbar navbar-expand-lg fixed-top" id="mainNav">
                <div className="container">
                    <a className="navbar-brand" href="#page-top"><img src="/Images/logoVoxerMenu.png" alt="..." /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        Menu
                        <i className="fas fa-bars ms-1"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/admin">Home</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="!#" id="navbarDropdownCall" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Call
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownCall">
                                    <li>
                                        <a className="dropdown-item " aria-current="page" href="/admin/call-group">Call Group</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item " aria-current="page" href="/admin/call-history">History Call</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item " aria-current="page" href="/admin/main-common-preferences">Main Common Preferences</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item " aria-current="page" href="/admin/game-word-list">Game Word List</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item " aria-current="page" href="/admin/join-call">Join List</a>
                                    </li>

                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="!#" id="navbarDropdownTest" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Test
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownTest">
                                    <li>
                                        <a className="dropdown-item " aria-current="page" href="/admin/questions">Questions</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item " aria-current="page" href="/admin/result-test">Result Test</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="!#" id="navbarDropdownPrivacy" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Privacy Policy
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownPrivacy">
                                    <li>
                                        <a className="dropdown-item " aria-current="page" href="/admin/code-conduct">Code Conduct</a>
                                    </li>
                                    <li >
                                        <a className="dropdown-item " aria-current="page" href="/admin/good-practice">Good Practice</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" aria-current="page" href="/admin/terms">Terms</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="!#" id="navbarDropdownReports" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Reports
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownReports">
                                    <li>
                                        <a className="dropdown-item " aria-current="page" href="/admin/evaluate-call">Evaluate Call</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item " aria-current="page" href="/admin/improve">Improve</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item " aria-current="page" href="/admin/report">Report App</a>
                                    </li>
                                </ul>
                            </li>


                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="!#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Settings
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li>
                                        <a className="dropdown-item " aria-current="page" href="/admin/faqs">Faqs</a>
                                    </li>
                                    <li >
                                        <a className="dropdown-item " aria-current="page" href="/admin/preferences">Preferences</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item " aria-current="page" href="/admin/words">Words</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item " aria-current="page" href="/admin/users">Users</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item " aria-current="page" href="/admin/lista-interesse">Lista Interesses</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item " aria-current="page" href="/admin/plans">Plans</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <div className="nav-link" aria-current="page" onClick={logout} style={{ cursor: "pointer" }}>Logout</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <div className="pt-5 mt-5">
                {children}
            </div>
        </div>
    )

}
export default LayoutAdmin

