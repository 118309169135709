import React, { useState, useEffect } from 'react';

import { useUpdateDocument } from '../../../hooks/useUpdateDocument';
import { useFetchDocument } from '../../../hooks/useFetchDocument';
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../../../lotties/loading';

const ImproveUpdate = () => {
    const { id } = useParams();
    const { document: improve } = useFetchDocument("improve", id);
    const [rating, setRating] = useState("");
    const [status, setStatus] = useState("");
    const [text, setText] = useState("");
    const [uid, setUid] = useState("");
    const [uidUser, setUidUser] = useState("");
    const [formError, setFormError] = useState("");

    // fill form data
    useEffect(() => {
        if (improve) {
            setRating(improve.rating);
            setStatus(improve.status);
            setText(improve.text);
            setUid(improve.uid);
            setUidUser(improve.uidUser);
        }
    }, [improve]);




    const navigate = useNavigate();

    const { updateDocument, response } = useUpdateDocument("improve");

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError("");

        // check values
        if (!rating || !status || !text || !uid || !uidUser) {
            setFormError("Por favor, preencha todos os campos!");
        }

        if (formError) return

        const data = {
            rating,
            status,
            text,
            uid,
            uidUser,
        };

        updateDocument(id, data);
        // redirect to home page
        navigate("/admin/improve");
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className='mt-3 mb-5'>
            <div className='container bg-light py-5'>
                <div className='row'>
                    <div className='mx-auto col-md-4 text-center'>
                        <h2>Editando Improve: {rating} </h2>
                        <p>Altere os dados do Improve como desejar</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6 mx-auto card p-5'>
                        <form onSubmit={handleSubmit}>
                            <div className='mb-3'>
                                <label htmlFor="rating" className="form-label">Rating</label>
                                <input

                                    className='form-control'
                                    type="text"
                                    name="text"
                                    required
                                    disabled
                                    placeholder="Rating"
                                    onChange={(e) => setRating(e.target.value)}
                                    value={rating}
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor="status" className="form-label">Status</label>
                                <input

                                    className='form-control'
                                    type="text"
                                    name="text"
                                    required
                                    disabled
                                    placeholder="Status"
                                    onChange={(e) => setStatus(e.target.value)}
                                    value={status}
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor="text" className="form-label">Text</label>
                                <input

                                    className='form-control'
                                    type="text"
                                    name="text"
                                    required
                                    disabled
                                    placeholder="Text"
                                    onChange={(e) => setText(e.target.value)}
                                    value={text}
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor="uid" className="form-label">Uid</label>
                                <input

                                    className='form-control'
                                    type="text"
                                    name="text"
                                    required
                                    disabled
                                    placeholder="uid"
                                    onChange={(e) => setUid(e.target.value)}
                                    value={uid}
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor="uidUser" className="form-label">Uid User</label>
                                <input

                                    className='form-control'
                                    type="text"
                                    name="text"
                                    required
                                    disabled
                                    placeholder="uidUser"
                                    onChange={(e) => setUidUser(e.target.value)}
                                    value={uidUser}
                                />
                            </div>
                            {response.loading && (
                                <button className="btn" disabled>
                                    <Lottie
                                        options={defaultOptions}
                                        height={400}
                                        width={400}
                                    />
                                </button>
                            )}
                            {(response.error || formError) && (
                                <p className="error">{response.error || formError}</p>
                            )}
                        </form>
                    </div>
                </div>

            </div>
        </div>
    );
};



export default ImproveUpdate;