import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const Home = () => {

    const { t } = useTranslation();


    return (
        <div>
            <header className="masthead">
                <div className="container px-5">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="mb-5 mb-lg-0 text-center text-lg-start">

                                <h1 className="display-1 lh-1 mb-3" style={{ color: "#04004F" }}>{t('whatIsIt')}</h1>
                                <p className="lead fw-normal  mb-5" style={{ color: "#302A94" }}>{t('voxer')}</p>
                                <div className="d-flex flex-column flex-lg-row align-items-center">
                                    <a className="me-lg-3 mb-4 mb-lg-0" href="https://apps.apple.com/us/app/voxer-global/id6472001248"><img className="app-badge" src="/Images/bt_apple_store.svg" alt="..." /></a>
                                    <a href="https://play.google.com/store/apps/details?id=br.global.voxer&hl=pt_BR"><img className="app-badge" src="/Images/bt_google_play.svg" alt="..." /></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="masthead-device-mockup">
                                <div className="screen">
                                    <img className="img-fluid" src="/img/welcome.png" alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <section >
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-8 mx-auto">
                            <h2 className="section-heading text-uppercase" style={{ color: "#04004F" }}>{t('voxperience')}</h2>
                        </div>
                        <div className="col-md-8 mx-auto">
                            <p className="lead fw-normal  mb-5 mb-lg-0" style={{ color: "#302A94" }}>{t('rest')}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="features" className="bg-light">
                <div className="container px-5">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-7 order-lg-1 mb-5 mb-lg-0">
                            <div className="container-fluid px-5">
                                <div className="row gx-5">
                                    <div className="col-md-6 mb-5">

                                        <div className="text-center">
                                            <img className="img-fluid" src="/img/usuario.png" alt="..." />
                                            <h3 className="font-alt" style={{ color: "#04004F" }}>{t('whoIsItFor')}</h3>
                                            <p className=" mb-0" style={{ color: "#302A94" }}>{t('voxerIsForAnyone')}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-5">

                                        <div className="text-center">
                                            <img className="img-fluid" src="/img/grupo.png" alt="..." />
                                            <h3 className="font-alt" style={{ color: "#04004F" }}>{t('weRea')}</h3>
                                            <p className=" mb-0" style={{ color: "#302A94" }}>{t('voxerIsAlways')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-5 mb-md-0">

                                        <div className="text-center">
                                            <img className="img-fluid" src="/img/diagrama.png" alt="..." />
                                            <h3 className="font-alt" style={{ color: "#04004F" }}>{t('groundbreaking')}</h3>
                                            <p className=" mb-0" style={{ color: "#302A94" }}>{t('ourAdvanced')}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">

                                        <div className="text-center">
                                            <img className="img-fluid" src="/img/vaivem.png" alt="..." />
                                            <h3 className="font-alt" style={{ color: "#04004F" }}>{t('thereMore')}</h3>
                                            <p className=" mb-0" style={{ color: "#302A94" }}>{t('voxerEvolving')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 order-lg-0">
                            <div className="screen">
                                <img className="img-fluid" src="/img/call.png" alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container px-5">
                    <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                        <div className="col-12 col-lg-5">
                            <h2 className="display-4 lh-1 mb-4" style={{ color: "#04004F" }}>{t('howDoesItWork')}</h2>
                            <p className="lead fw-normal  mb-5 mb-lg-0" style={{ color: "#302A94" }}>{t('ourAppOnly')}</p>
                        </div>
                        <div className="col-sm-8 col-md-4">
                            <div className="px-5 px-sm-0"><img className="img-fluid" src="/img/preferences.png" alt="..." /></div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-light">
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase" style={{ color: "#04004F" }}>{t('downloadOurApp')}</h2>
                    </div>
                    <div className="row text-center">
                        <div className="col-md-12">
                            <h5 className="section-heading text-uppercase" style={{ color: "#04004F" }}>{t('1check')}</h5>
                            <p className="" style={{ color: "#302A94" }}>{t('ourQuick')}</p>
                            <h5 className="section-heading text-uppercase" style={{ color: "#04004F" }} >{t('2choose')}</h5>
                            <p className="" style={{ color: "#302A94" }}>{t('bySharing')}</p>
                            <h5 className="section-heading text-uppercase" style={{ color: "#04004F" }}>{t('3youre')}</h5>
                            <p className="" style={{ color: "#302A94" }}>{t('connect')}</p>
                        </div>
                    </div>
                </div>

            </section>

            <section id="download">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <h2 className="section-heading text-uppercase" style={{ color: "#04004F" }}>{t('voxerConnects')}</h2>
                        </div>
                        <div className="col-md-12">
                            <a href='https://apps.apple.com/us/app/voxer-global/id6472001248'><Image className='img-fluid p-3' src="/Images/bt_apple_store.svg"></Image></a>
                            <a href='https://play.google.com/store/apps/details?id=br.global.voxer&hl=pt_BR'><Image className='img-fluid p-3' src="/Images/bt_google_play.svg"></Image></a>
                        </div>
                    </div>
                </div>
            </section>
            <section className='bg-light'>
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="col-lg-12">
                                <div
                                    className="video"
                                    style={{
                                        position: "relative",
                                        paddingBottom: "56.25%" /* 16:9 */,
                                        paddingTop: 25,
                                        height: 0
                                    }}
                                >
                                    <iframe
                                        title='videoVoxer'
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%"
                                        }}
                                        src={`https://www.youtube.com/embed/8W-ew78IfEg`}
                                        frameBorder="0"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}


export default Home;