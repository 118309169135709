import React, { useState, useEffect } from 'react';

import { useUpdateDocument } from '../../../hooks/useUpdateDocument';
import { useFetchDocument } from '../../../hooks/useFetchDocument';
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../../../lotties/loading';

const Users = () => {
    const { id } = useParams();
    const { document: users } = useFetchDocument("users", id);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [formError, setFormError] = useState("");

    // fill form data
    useEffect(() => {
        if (users) {
            setName(users.name);
            setEmail(users.email);
        }
    }, [users]);




    const navigate = useNavigate();

    const { updateDocument, response } = useUpdateDocument("users");

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError("");

        // check values
        if (!name || !email) {
            setFormError("Por favor, preencha todos os campos!");
        }

        if (formError) return

        const data = {
            name,
            email,
        };

        updateDocument(id, data);
        // redirect to home page
        navigate("/admin/users");
    };


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className='mt-3 mb-5'>
            <div className='container bg-light py-5'>
                <div className='row'>
                    <div className='mx-auto col-md-4 text-center'>
                        <h2>Visualizando Users: {name} </h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6 mx-auto card p-5'>
                        <form onSubmit={handleSubmit}>
                            <div className='mb-3'>
                                <label htmlFor="name" className="form-label">Name</label>
                                <input

                                    className='form-control'
                                    type="text"
                                    name="text"
                                    required
                                    disabled
                                    placeholder="Name"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor="email" className="form-label">E-mail</label>
                                <input

                                    className='form-control'
                                    type="text"
                                    name="text"
                                    required
                                    disabled
                                    placeholder="Email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                            </div>
                            {response.loading && (
                                <button className="btn" disabled>
                                    <Lottie
                                        options={defaultOptions}
                                        height={400}
                                        width={400}
                                    />
                                </button>
                            )}
                            {(response.error || formError) && (
                                <p className="error">{response.error || formError}</p>
                            )}
                        </form>
                    </div>
                </div>

            </div>
        </div>
    );
};



export default Users;