import React, { useState } from 'react';

import { useInsertDocument } from '../../../hooks/useInsertDocument';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../../../lotties/loading';

const WordsCreate = () => {
    const [title, setTitle] = useState("");
    const [CEFR, setCEFR] = useState("");
    const [uid, setUid] = useState("");
    const [formError, setFormError] = useState("");



    const navigate = useNavigate();

    const { insertDocument, response } = useInsertDocument("words");

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError("");

        // check values
        if (!title || !CEFR || !uid) {
            setFormError("Por favor, preencha todos os campos!");
        }

        if (formError) return
        setUid(new Date().getTime());
        insertDocument({
            title,
            CEFR,
            score: 0,
            uid: new Date().getTime().toString(),
        });

        // redirect to home page
        navigate("/admin/words");
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className='mt-3 mb-5'>
            <div className='container bg-light py-5'>
                <div className='row'>
                    <div className='mx-auto col-md-4 text-center'>
                        <h2>Criar Words</h2>
                        <p>Adicione uma palavra!</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6 mx-auto card p-5'>
                        <form onSubmit={handleSubmit}>
                            <div className='mb-3'>
                                <label htmlFor="title" className="form-label">Title</label>
                                <input
                                    className='form-control'
                                    type="text"
                                    name="text"
                                    required
                                    placeholder="Title"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor="CEFR" className="form-label">CEFR</label>
                                <select
                                    className='form-select'
                                    type="text"
                                    name="text"
                                    required
                                    onChange={(e) => setCEFR(e.target.value)}
                                    value={CEFR}
                                >
                                    <option value="">Selecione uma opção!</option>
                                    <option value="A1">A1</option>
                                    <option value="A2">A2</option>
                                    <option value="B1">B1</option>
                                    <option value="B2">B2</option>
                                    <option value="C1">C1</option>

                                </select>
                            </div>
                            {!response.loading && <button className="btn btn-md btn-success">Criar Words!</button>}
                            {response.loading && (
                                <button className="btn" disabled>
                                    <Lottie
                                        options={defaultOptions}
                                        height={400}
                                        width={400}
                                    />
                                </button>
                            )}
                            {(response.error || formError) && (
                                <p className="error">{response.error || formError}</p>
                            )}
                        </form>
                    </div>
                </div>

            </div>
        </div>
    );
};



export default WordsCreate;