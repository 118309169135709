import React, { useState, useEffect } from 'react';

import { useUpdateDocument } from '../../../hooks/useUpdateDocument';
import { useFetchDocument } from '../../../hooks/useFetchDocument';
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../../../lotties/loading';

const FaqsUpdate = () => {
    const { id } = useParams();
    const { document: faq } = useFetchDocument("faqs", id);
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [uid, setUid] = useState("");
    const [formError, setFormError] = useState("");

    // fill form data
    useEffect(() => {
        if (faq) {
            setDescription(faq.description);
            setTitle(faq.title);
            setUid(faq.uid);
        }
    }, [faq]);




    const navigate = useNavigate();

    const { updateDocument, response } = useUpdateDocument("faqs");

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError("");

        // check values
        if (!description || !title || !uid) {
            setFormError("Por favor, preencha todos os campos!");
        }

        if (formError) return

        const data = {
            description,
            title,
            uid,
        };

        updateDocument(id, data);
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className='mt-3 mb-5'>
            <div className='container bg-light py-5'>
                <div className='row'>
                    <div className='mx-auto col-md-4 text-center'>
                        <h2>Editando Faqs: {title} </h2>
                        <p>Altere os dados da Faq como desejar</p>
                        {(response.success === true) && (
                            <div className="alert alert-success" role="alert">
                                Atualizado com sucesso
                            </div>
                        )

                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6 mx-auto card p-5'>
                        <form onSubmit={handleSubmit}>
                            <div className='mb-3'>
                                <label htmlFor="title" className="form-label">Title</label>
                                <input

                                    className='form-control'
                                    type="text"
                                    name="text"
                                    required
                                    placeholder="Title"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor="description" className="form-label">Description</label>
                                <textarea

                                    className='form-control'
                                    type="text-area"
                                    name="text"
                                    required
                                    placeholder="Description"
                                    onChange={(e) => setDescription(e.target.value)}
                                    value={description}
                                />
                            </div>

                            {!response.loading && <button className="btn btn-md btn-success">Atualizar Faqs!</button>}
                            {response.loading && (
                                <button className="btn" disabled>
                                    <Lottie
                                        options={defaultOptions}
                                        height={400}
                                        width={400}
                                    />
                                </button>
                            )}
                            {(response.error || formError) && (
                                <p className="error">{response.error || formError}</p>
                            )}
                        </form>
                    </div>
                </div>

            </div>
            {
                (response.success === true) && (
                    setTimeout(() => {
                        navigate("/admin/faqs")
                    }, 2000)
                )
            }
        </div>
    );
};



export default FaqsUpdate;