import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-autofill-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css';
import 'datatables.net-buttons/js/buttons.colVis.mjs';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-buttons/js/buttons.print.mjs';
import 'datatables.net-colreorder-bs5';
import 'datatables.net-fixedcolumns-bs5';
import 'datatables.net-fixedheader-bs5';
import 'datatables.net-keytable-bs5';
import 'datatables.net-responsive-bs5';
import 'datatables.net-rowgroup-bs5';
import 'datatables.net-rowreorder-bs5';
import 'datatables.net-scroller-bs5';
import 'datatables.net-searchbuilder-bs5';
import 'datatables.net-searchpanes-bs5';
import 'datatables.net-select-bs5';
import 'datatables.net-staterestore-bs5';


import { Link } from "react-router-dom";


import { useFetchDocuments } from "../../../hooks/useFetchDocuments";


const Terms = () => {

    const { documents: terms } = useFetchDocuments("terms", null, null);
    const tableRef = useRef(null);
    useEffect(() => {
        if (terms) {
            $(tableRef.current).DataTable({

                retrieve: true,
                dom: 'BCRSZlfrtip',
                buttons: [
                    'excelHtml5',
                    'autoWidth',
                    'pdf',
                    'csv'
                ]
            });
        }


    }, [terms]);

    return (
        <div className='mt-3 mb-5'>
            <div className='container'>
                <div className='row'>
                    <div className='mx-auto col-md-4 text-center'>
                        <h2 >Terms</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 mx-auto'>
                        <table ref={tableRef} className="display stripe w-100" data-page-length="10" >
                            <thead>
                                <tr>
                                    <th>Título</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {terms &&
                                    terms.map((value) => (
                                        <tr key={value.uid}>
                                            <td >
                                                <p>{value.title}</p>
                                            </td>
                                            <td>
                                                <Link to={`/admin/terms/update/${value.id}`} className="btn btn-warning m-1">
                                                    Edit
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};



export default Terms;